/** @jsx jsx */
import { ComparisonChart } from '@charts';
import { ProtectedRouteAndRedirect } from 'src/components/composite/ProtectedRoute';
import { ChartContextWrapper } from 'src/components/composite/chart-generator/shared';
import { jsx } from 'theme-ui';

export default function () {
  return (
    <ChartContextWrapper>
      <ProtectedRouteAndRedirect path='/comparison-chart' component={ComparisonChart} />
    </ChartContextWrapper>
  );
}
